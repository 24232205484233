import { twMerge } from '@troon/tailwind-preset/merge';
import type { ComponentProps } from 'solid-js';

export function Grid(props: ComponentProps<'div'>) {
	return (
		<div class={twMerge('grid w-full grid-cols-1 gap-4 gap-y-8 lg:grid-cols-12 lg:gap-12', props.class)}>
			{props.children}
		</div>
	);
}

export function GridHalf(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-6', props.border && 'rounded border border-neutral-500', props.class)}
		/>
	);
}

export function GridThird(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-4', props.border && 'rounded border border-neutral-500', props.class)}
		/>
	);
}

export function GridQuarter(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-3', props.border && 'rounded border border-neutral-500', props.class)}
		/>
	);
}

export function GridThreeQuarter(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-9', props.border && 'rounded border border-neutral-500', props.class)}
		/>
	);
}

export function GridTwoThirds(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-8', props.border && 'rounded border border-neutral-500', props.class)}
		/>
	);
}

export function GridMain(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-8', props.border && 'rounded border border-neutral-500', props.class)}
		/>
	);
}

export function GridSidebar(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge(
				'col-span-1 lg:col-span-4 lg:row-start-auto',
				props.border && 'rounded border border-neutral-500',
				props.class,
			)}
		/>
	);
}
